<template>
    <v-card class="pa-2">
        <v-container class="text-center" >
            <div class="mb-6">
                <h3 class="my-2">
                    ¿Quieres saber sobre el proceso de prácticas?
                </h3>
                <v-btn
                    elevation="0"
                    class="mr-4"
                    color="primary"
                    @click="info_proceso_url()"
                    >Revisar proceso</v-btn
                >
            </div>
            
            <div class="mb-6">
                <h3 class="mt-2 mb-2">
                    Revisa las preguntas frecuentes si tienes dudas
                </h3>
                <v-btn
                    elevation="0"
                    class="mr-4"
                    color="primary"
                    @click="preguntas_url()"
                    >Preguntas Frecuentes</v-btn
                >
            </div>
        

            <h3 class="mt-5 mb-2">Revisa la charla de prácticas</h3>

            <div class="video-responsive mb-3" >
                <iframe
                    src="https://www.youtube.com/embed/zCjyUdq32Ic?si=HpZzt9ZZ40u3f75d"
                    frameborder="0"
                    allowfullscreen
                ></iframe>
            </div>
            <v-btn
                elevation="0"
                class="mr-2"
                color="primary"
                target="_blank"
                href="https://drive.google.com/file/d/1ka8OQcbP_Ob-FX9fzK0xNHdIjBgX2wOy/view?usp=sharing"
                ><h5>Presentación de la charla</h5></v-btn
            >
        </v-container>
    </v-card>
</template>

<script>

export default {
    components: {},
    methods: {
        preguntas_url() {
            var show_logo=false;
            if (this.$route.path == "/"){
                show_logo=true;
            } 
            this.$router.push({ name: "Preguntas Frecuentes", params: { back_info: this.$route.path, show_logo: show_logo} });
        },
        info_proceso_url() {
            var show_logo=false;
            if (this.$route.path == "/"){
                show_logo=true;
            } 
            this.$router.push({ name: "Información del Proceso", params: { back_info: this.$route.path, show_logo: show_logo} });
        },
    },
};
</script>

<style>
.video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
    border-radius: 10px;
    margin: 0 auto; /* Centra el video si es más pequeño que su contenedor */

}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 10px; 

}
</style>
